.our-team-wrapper {

    position: relative;

    .our-team-button-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mediaquery(md, max) {
            padding: 15px 0 10px 0;
        }
    }

    .our-team-limit-warning {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #262626;
        @include mediaquery(md, max) {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .add-our-team-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 15px 0;

        svg {
            margin-right: 5px;
        }
    }

    .product-table {
        margin-bottom: 100px;
        .sku-id {
            display: block;
        }
        .status {
            border: none;

            .active {
                svg {
                    fill: green;
                }
            }
        }
    }

    .add-our-team-popup-wrapper {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 6;

        .add-our-team-popup {
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            left: 50%;
            overflow: auto;
            padding: 30px;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 73%;
            z-index: 1;
            overflow-x: hidden;
        }

        .heading {
            color: #262626;
            font-size: 24px;
            font-weight: 600;
            line-height: 25px;

            @include mediaquery(md, max) {
                font-size: 20px;
            }
        }

        .hr {
            opacity: 0.1;
            border: 1px solid #000000;
            margin-bottom: 28px;
            width: 99%;
            margin: 15px 0;
        }

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                cursor: pointer;
                height: 25px;
                width: 25px;
                fill: #C4C4C4;
            }

            &:hover {
                svg {
                    fill: red;
                }
            }
        }

        .add-our-team-popup-description {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            gap: 20px;
            // padding-right: 15px;

            max-height: 400px;
            overflow-y: scroll;

            @include mediaquery(md, max) {
                // flex-direction: column;
                display: block;
                padding-right: 15px;
            }

            .form-wrapper {
                flex: 1 1 50%;

                @include mediaquery(md, max) {
                    border-right: none;
                }

                .form-control {
                    div {
                        width: 500px;

                        @include mediaquery(md, max) {
                            width: 99%;
                            justify-content: space-between;
                        }
                    }
                }

                .label {
                    color: #262626;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 18px;
                }

                .padding-b-20px {
                    padding-bottom: 20px;
                }

                .radio-group {
                    margin-bottom: 20px;

                    label {
                        align-items: flex-start;
                        height: 0;

                        span {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }

                .company-list {
                    margin-top: 10px;
                }

                .team-members-role-txt {
                    color: #262626;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 30px;
                }

                .team-members-role-txt {
                    margin: 20px 0;
                }


            }

            .role-description-wrapper {
                flex: 1 1 50%;
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                padding-left: 20px;
                margin-bottom: 30px;

                @include mediaquery(md, max) {
                    border-left-width: 0;
                }

                .heading {
                    color: #262626;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 18px;
                    padding-bottom: 10px;
                }

                .description {
                    color: dimgray;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 0px 0 20px;
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-bottom: 10px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    width: 97%;

                    svg {
                        margin-right: 10px;
                    }
                }

                .role-wrapper,
                .sub-role-wrapper {

                    .role-heading {
                        color: #262626;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 16px;
                        padding-bottom: 10px;
                    }

                    .sub-role-heading {
                        color: #262626;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 16px;
                        padding-bottom: 10px;
                    }

                    .role-description,
                    .sub-role-description {
                        color: dimgray;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        width: 100%;
                    }
                }

                .sub-role-wrapper {
                    display: inline-block;
                    margin: 20px 0 0 40px;

                    @include mediaquery(md, max) {
                        margin-left: 0;
                    }
                }
            }

        }

        .button-wrapper {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 20px;

            button {
                margin-left: 30px;
                text-transform: capitalize;
            }
        }

        .loader-component-wrap {
            .loader-center {
                z-index: 2;
            }
        }
    }

    .more-options-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .tooltip-wrapper {
            right: 20px;
        }
    }

    .not-data-found {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
        font-weight: 500;
        font-size: 20px;
        color: #313E42;
        opacity: 0.5;
        cursor: pointer;
    }
}

#demo-multiple-checkbox {
    width: 100%;
    margin: 0;
    width: 100%;
    background: #F3F3F4;
    border-radius: 6px;
    font-size: 14px;

    .MuiInputBase-colorPrimary {
        &:hover {
            fieldset {
                // border-right-width: 1px;
                // border-top-right-radius: 5px;
                // border-bottom-right-radius: 5px;
            }
        }
    }

    .MuiChip-root {
        background: rgba(105, 129, 241, 0.3);
        border: 1px dashed $secondary-color;
        border-radius: 20px;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: $secondary-color;
        width: auto;

        &:hover {
            background: #fff;
        }

        svg {
            fill: $secondary-color;

            &:hover {
                fill: red;
            }
        }
    }
}