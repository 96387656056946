html, body {
	overscroll-behavior: none;
	font-family: $font-inter !important;
}

a {
	color: $secondary-color;
	text-decoration: none;
}

.flex {
	display: flex;
}

.flex-items-center {
	align-items: center;
}

.flex-justify-content-space-between {
	justify-content: space-between;
}

.flex-justify-content-center {
	justify-content: center;
}

.flex-justify-content-end {
	justify-content: flex-end;
}

.align-items-center {
	align-items: center !important;
}

.flex-justify-content-end {
	justify-content: flex-end;
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.gap-10 {
	gap:10px;
}

.position-relative {
	position: relative !important;
}

.pr-10 {
	padding-right: 10px !important;
}
.z-9999 {
	z-index: 9999;
}
.w-100 {
	width: 100%;
}

ul {
	list-style-type: none;
}

.site-wrap {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.last-name-style {
	@include mediaquery(md, max) {
		margin-top: 25px;
	}
}

.label {
	font-family: $font-inter !important;
}

input, #country-code-select {
	padding: 11px 14px !important;
}

.dot {
	color: $secondary-color;
}

.Mui-error:hover fieldset {
	// border-color: transparent !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	// border-color: $secondary-color !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgb(66 97 238);
}

.phone-number-wrapper {
	fieldset {
		border: 0;
		legend {
			display: none;
		}
	}
}

#phone-number ~ fieldset {
	border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.MuiSelect-nativeInput ~ fieldset {
	// border-right-width: 0;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
}

// Show element only in desktop (above 1024)
@include mediaquery(lg, max) {
  .only-desktop {
      display: none !important;
  }
}
// Show element only in mobile
@include mediaquery(md, min) {
  .only-mobile {
      display: none !important;
  }
}
// Hide element on mobile
@include mediaquery(md, max) {
  .not-mobile {
      display: none !important;
  }
}
// Hide element in desktop
@include mediaquery(lg) {
  .not-desktop {
      display: none !important;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.margin-bottom-28 {
	margin-bottom: 28px !important;	
}

.margin-bottom-10 {
	margin-bottom: 10px !important;	
}

.margin-top-28 {
	margin-top: 28px !important;	
}

.text-center {
	text-align: center;
}

.overflow-hide {
	overflow: hidden;
}

.visibility-hidden {
	visibility: hidden;
}

#email {
	padding-right: 40px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.m-mt-20 {
	@include mediaquery(md, max) {
		margin-top: 20px;
	}
}

.hide-scroll {
	overflow: hidden;
}

.element-vertically-center {
	display: flex;
    justify-content: center;
    align-items: center;
}

.width-0 {
	width: 0;
}

.display-none {
	display: none !important;
}

button {
	box-shadow: none !important;
}

legend {
	// display: none !important;
}

.margin-btm-10 {
	margin-bottom: 10px;
}

.margin-btm-15 {
	margin-bottom: 15px !important;
}
.margin-margin-30 {
	margin-bottom: 30px;
}
.margin-top-15 {
	margin-top: 15px !important;
}
.margin-bottom-0 {
	margin-bottom: 0;
}
.profile-country, .country-brand, .year-establish {
    fieldset {
		top: 0px !important;
		legend {
			display: none !important;
		}
	}
}

.close-icon {
	&:hover {
		svg {
			fill: red;
		}
	}
}

.opacity-20P {
	opacity: 0.2 !important;
}

.w-90-popup-content {
	@include mediaquery(md, max) {
		width: 90%;
	}
}

.items-align-start {
	align-items: flex-start !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.margin-bottom-13 {
	margin-bottom: 13px !important;
}

.position-absolute {
	position: absolute;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked, 
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
	color: $secondary-color !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
	color:#686868;
}

.for-yes-no {
	margin-bottom: -10px !important; 
	.form-control {
		p {
			margin-bottom: 0 !important;
		}
	}
}

.for-yes-no-1 {
	margin-bottom: 0px !important; 
		p {
			margin-bottom: 0 !important;
		}
}

.information-icon {
	display: flex;
    justify-content: flex-start;
    align-items: flex-start;
	gap: 5px;
	span {
		display: flex;
		cursor: pointer;
		svg {
			// transform: scale(.3);
			zoom: 35%;
		}
		&.svg {
			svg {
				zoom: unset;
			}
		}
		&:hover {
			svg {
				cursor: pointer;
				fill: gray;
				
				g {
					path {
							fill: #000;
					}
				}
			}
		}
	}
	
	.custom-tt {
		&::after {
			width: 250px;
			white-space: break-spaces;
			line-height: 12px;
		}
	}

	.custom-tt-2 {
		&::after {
			width: 150px;
			white-space: break-spaces;
			line-height: 12px;
			word-break: break-word;
		}
	}
	.custom-tt-2-1 {
		&::after {
			width: 150px;
			white-space: break-spaces;
			line-height: 12px;
			word-break: break-word;
		}
	}

	&.information-icon-block {
		display: block;
		[tooltip]:hover::before, [tooltip]:hover::after {
			@include mediaquery(md) {
				display: none;
			}
		}
	}
}



.product-card- {
	.custom-tt {
		&::after {
			width: 250px;
			white-space: break-spaces;
			line-height: 12px;
		}
	}
}

.information-icon-2 {
	@include mediaquery(md, max) {
		align-items: flex-start;
	}
}

// .width-250px {
// 		&::after {
// 			width: 250px;
// 		}
// 	}

// .line-height12px {
// 	line-height: 12px;
// }

.margin-zero {
	margin-top: 0 !important;
}
.padding-top24 {
	padding-top: 24px !important;
}

.margin-bottom-5 {
	margin-bottom: 5px !important;
}

.tt-for-m {
	@include mediaquery(md, max) {
		width: 69%;
	}
}

.multiselect-option-break {
	@include mediaquery(md, max) {
		white-space: break-spaces !important;
	}
}

em {
	font-style: unset;
}

.multiselect-wrapper {
	label {
		color: rgba(8, 12, 41, 0.4);
	}
}

// .read-more-popup {
// 	background-color: rgba(39, 145, 216,0.710);
//     height: 100%;
//     width: 100%;
//     position:absolute;
//     top: 0;
// 	left: 0;
// 	z-index: 1;

// 	.popup {
// 		height: 400px;
// 		width: 50%;
// 		background-color: white;
// 		position: absolute;
// 		top: 25%;
// 		right: 25%;
// 		z-index: 2;

// 		.popup-header {
// 			display: flex;
// 			justify-content: space-between;
// 			padding: 0 30px 0 15px;
// 			border-bottom: 2px solid black;
// 		}
// 	}
// }

.btn-read-more-content {
	// margin-left: 10px;
	margin-top: 5px;
}

.marginTop100 {
	margin-top: 100px !important;
}

.close-tooltip-icon, .open-tooltip-icon {
	transform: rotate(0deg) !important;
}

.w-full {
	width: 100%;
}
.text-left {
	text-align: left;
}

.modal-title {
	width: 100%;
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    display: inline-block !important;
    vertical-align: middle;
}

.rdw-link-modal {
	height: auto !important;
	left: -139px !important;
	@include mediaquery(md, max) {
		left: -70px !important;
		position: unset !important;
	}
}

.conformation-popup-wrapper .popup-wrap .text {
	padding: 0 10px;
}

.custom-tt-3 {
	&::after {
		width: 150px;
		white-space: break-spaces;
		line-height: 12px;
		word-break: break-word;
	}
}


.information-icon-cu {
	
	span {
		
		&.svg {
			svg {
				zoom: unset;
			}
		}
		&:hover {
			svg {
				// cursor: pointer;
				// fill: gray;
				
				g {
					path {
							fill: #000;
					}
				}
			}
		}
	}

	.custom-tt-2 {
		&::after {
			width: 150px;
			white-space: break-spaces;
			line-height: 12px;
			word-break: break-word;
		}
	}
}

input[type="checkbox"] {
    accent-color: #3357FF;
}

.mb-0 {
	margin-bottom: 0 !important;
}