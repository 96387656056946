.category-section-wrapper {
    .heading-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 30px;

        .heading {
            font-weight: 700;
            font-size: 28px;
            line-height: 28px;
            color: #313E42;
        }

        .close-icon {
            svg {
                fill: #C4C4C4;
                height: 30px;
                width: 30px;
                cursor: pointer;
            }
        }
    }

    .category-display-wrap {
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        margin-top: 30px;

        .table-heading {
            font-weight: 600;
            // font-size: 18px;
            line-height: 20px;
        }

        .fivep {
            width: 5%;
        }
        .tenp {
            width: 10%;
        }
        .sixtyp {
            width: 60%;
        }
        .fiftyp {
            width: 50%;
        }
        .fourtyp {
            width: 40%;
        }
        .thirtyp {
            width: 30%;
        }
        .twentyp {
            width: 20%;
        }

        .category-name {
           word-break: break-all;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #262626;
            padding-right: 20px;
            align-items: center;
            font-family: $font-inter;
        }
        
        .category-date, .category-count {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #686868;
            font-family: $font-inter;
        }

        .category-icons-wrap {
            display: flex;
            gap: 30px;

            .delete-icon {
                cursor: pointer;
                &:hover {
                    svg {
                        path {
                            stroke: red;
                        }
                    }
                }
            }
        }
    }
}