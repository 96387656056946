.website-product-details-wrapper {
    background-color: #f0f1f1;

    .box {
        display: block;
        border: 1px solid rgb(218, 221, 225);
        border-radius: 3px;
        background-color: rgb(255, 255, 255);
        margin-bottom: 16px;

        .box-title {
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            font-family: $font-inter;
            font-size: 16px;
            font-weight: 600;
            padding-left: 24px;
            padding-right: 24px;
            padding: 24px;
        }

        .border-btm {
            border-bottom: 1px solid rgb(218, 221, 225);
        }

        .box-containr {
            padding: 24px;

            .box-inner-details {
                height: 100%;

                &:first-of-type {
                    .box-inner-sub-details {
                        margin-top: 0 !important;
                    }
                }

                .box-inner-title {
                    font-family: $font-inter;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000 !important;
                    margin-bottom: 16px;
                    display: inline-block;
                }

                .box-inner-top-space {
                    margin-top: 0 !important;
                }

                .box-inner-sub-details {

                    // margin-top: 16px;
                    &.border-bottom {
                        border-bottom: 1px dashed rgb(218, 221, 225);
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }

                    .box-column-wrap {

                        // display: flex;
                        // -webkit-box-align: stretch;
                        // align-items: stretch;
                        // -webkit-box-pack: start;
                        // justify-content: flex-start;
                        // flex-flow: row wrap;
                        // margin-left: -32px;
                        // margin-right: -32px;
                        .box-col-one {
                            display: grid;
                            grid-template-columns: 50% 50%;
                            gap: 16px;

                            @include mediaquery(md, max) {
                                display: block;
                            }

                            .box-row-wrap {
                                padding: 8px 0px;
                                padding-right: 10px;
                                display: flex;
                                -webkit-box-align: stretch;
                                align-items: stretch;
                                -webkit-box-pack: start;
                                justify-content: flex-start;
                                flex-flow: row wrap;

                                @include mediaquery(md, max) {
                                    display: block;
                                }

                                .box-inner-column-33p {
                                    flex-basis: 45%;
                                    max-width: 45%;

                                    @include mediaquery(md, max) {
                                        flex-basis: 100%;
                                        max-width: 100%;
                                    }

                                    position: relative;

                                    span {
                                        // position: absolute;
                                        // right: 0;
                                    }
                                }

                                .box-inner-column-66p {
                                    flex-basis: calc(55% - 10px);
                                    max-width: calc(55% - 10px);

                                    // padding-left: 10px;
                                    @include mediaquery(md, max) {
                                        flex-basis: 100%;
                                        max-width: 100%;
                                        padding-top: 6px;
                                        padding-left: 0;
                                    }

                                    .description-text {

                                        &:not([flow])::after,
                                        &:not([flow])::before {
                                            left: 5%;
                                        }
                                    }

                                    // span {
                                    img {
                                        &.nutri-img {
                                            width: 10%;
                                            border-radius: 5px;
                                            border: #262626 solid 1px;
                                            cursor: pointer;
                                        }
                                    }

                                    // }
                                }

                                .box-inner-column-100p {
                                    flex-basis: 100%;
                                    max-width: 100%;

                                    @include mediaquery(md, max) {
                                        flex-basis: 100%;
                                        max-width: 100%;
                                        padding-top: 6px;
                                    }
                                }

                                .chips-top-space {
                                    margin-top: 16px;
                                }

                                .chips-list {
                                    // margin-top: 15px;
                                    display: flex;
                                    flex-wrap: wrap;
                                    row-gap: 10px;

                                    .chips {
                                        background: #e3e3e3 !important;
                                        border-radius: 17.5px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-weight: 600;
                                        font-size: 12px;
                                        line-height: 17px;
                                        color: gray;
                                        // opacity: 0.65;
                                        margin-right: 10px;
                                        padding: 5px 7px 5px 11px;
                                        border: gray dashed 1px;
                                        gap: 5px;
                                    }
                                }

                                .b-title {
                                    font-family: $font-inter;
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: rgba(0, 0, 0, 0.6);
                                    // text-transform: uppercase;
                                    display: inline-block !important;
                                    vertical-align: middle;

                                    [tooltip] {
                                        display: inline-block !important;
                                        vertical-align: middle;
                                        padding-left: 5px;
                                    }

                                    .box-inner-title {
                                        color: #262626;
                                    }
                                }

                                .b-details {
                                    font-family: $font-inter;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #262626;
                                    word-break: break-all;
                                }

                                .margin-top-16 {
                                    margin-top: 16px;
                                }

                                .two-col-grid {
                                    display: grid;
                                    grid-template-columns: 33.33% 33.33% 33.33%;
                                    margin-top: 16px;
                                    // column-gap: 20px;
                                    row-gap: 20px;

                                    @include mediaquery(md, max) {
                                        grid-template-columns: 100%;
                                    }

                                    .retailers-list {
                                        display: flex;
                                        justify-content: space-between;
                                        padding-right: 20px;

                                        .chips-list {
                                            .chips {
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .box-inner-full-column-wrap {
                            flex-basis: 100%;
                            max-width: 100%;
                            // padding-left: 32px;
                            // padding-right: 32px;
                            display: block;

                            .box-row-wrap {
                                padding: 8px 0px;
                                display: flex;
                                -webkit-box-align: stretch;
                                align-items: stretch;
                                -webkit-box-pack: start;
                                justify-content: flex-start;
                                flex-flow: row wrap;

                                .box-inner-full-column-25P {
                                    flex-basis: 22.5%;
                                    max-width: 22.5%;

                                    @include mediaquery(md, max) {
                                        flex-basis: 100%;
                                        max-width: 100%;
                                    }
                                }

                                .box-inner-full-column-75P {
                                    flex-basis: calc(77.5% - 10px);
                                    max-width: calc(77.5% - 10px);

                                    // padding-left: 10px;
                                    @include mediaquery(md, max) {
                                        flex-basis: 100%;
                                        max-width: 100%;
                                        padding-top: 6px;
                                        padding-left: 0;
                                    }
                                }

                                .b-title {
                                    font-family: $font-inter;
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: rgba(0, 0, 0, 0.6);
                                    // text-transform: uppercase;
                                }

                                .b-details {
                                    font-family: $font-inter;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #262626;
                                }

                                p {
                                    margin: 0;
                                }

                                ul {
                                    margin: 0;
                                    padding: 0;
                                    list-style-type: inherit;
                                }

                                ol {
                                    margin: 0;
                                    padding: 0 0 0 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .box-container {
        width: 1200px;
        padding: 0;
        margin: 0 auto;
        padding-bottom: 20px;

        @include mediaquery(md, max) {
            width: 100%;
        }
    }

    @include mediaquery(md, max) {
        padding: 0;
        width: 100%;
    }

    .header-section-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        flex-direction: column;
        gap: 5px;
        background: #fff;
        padding: 24px;
        border: 1px solid rgb(218, 221, 225);
        border-radius: 3px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-bottom: 16px;

        @include mediaquery(md, max) {
            // margin-top: 20px;
            position: relative;
            padding-bottom: 0;
        }

        .header-wrap {
            display: flex;
            align-items: center;
            width: 100%;

            @include mediaquery(md, max) {
                flex-direction: column;
                align-items: flex-start;
                text-align: center;
                margin-bottom: 20px;
            }

            .back-btm-wrap {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: rgba(38, 38, 38, 0.7);
                display: flex;
                align-items: center;
                cursor: pointer;
                text-decoration: none;
                width: 160px;

                @include mediaquery(md, max) {
                    width: 100%;
                }

                svg {
                    fill: rgba(38, 38, 38, 0.7);
                    margin-right: 10px;
                }

                &:hover {
                    svg {
                        fill: $secondary-color;
                    }

                    color: $secondary-color;
                }
            }

            .brand-image {
                width: auto;
                // margin-left: 50px;

                @include mediaquery(md, max) {
                    margin-left: 0;
                    width: 100%;
                }

                img {
                    width: auto;
                    height: 75px;
                    object-fit: contain;
                    border: #f1f1f1 solid 1px;
                    padding: 5px;
                }
            }

            .tagline-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 60px;
                margin-left: 20px;
                // width: calc(100% - 420px);

                @include mediaquery(md, max) {
                    margin-left: 0;
                    width: 100%;
                    justify-content: flex-start;
                    height: auto;
                    padding-bottom: 20px;
                    gap: 7px;
                }

                .name {
                    display: flex;
                    align-items: center;

                    @include mediaquery(md, max) {
                        justify-content: center;
                    }

                    .text {
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 34px;
                        color: #262626;
                        text-transform: capitalize;
                        text-align: left;

                        @include mediaquery(md, max) {
                            text-align: center;
                            font-size: 18px;
                            line-height: 28px;
                            margin-top: 10px
                        }
                    }

                    .owner {
                        background: rgba(105, 129, 241, 0.3);
                        border: 1px solid $secondary-color;
                        border-radius: 20px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 22px;
                        color: $secondary-color;
                        padding: 2px 20px;
                        margin-left: 30px;
                        white-space: nowrap;

                        @include mediaquery(md, max) {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }

                .tagline {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #262626;
                    opacity: 0.7;
                    word-break: break-all;

                    @include mediaquery(md, max) {
                        font-size: 14px;
                    }
                }
            }
        }

        .share-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;

            .share-icon {
                background-color: #000000;
                padding: 8px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;

                svg {
                    height: 15px;
                    width: 15px;
                }
            }

            .text {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
            }
        }
    }

    .manage-section {
        margin: 5px 0 0 0;
        position: sticky;
        top: 0px;
        z-index: 2;
        background: #fff;
        padding-top: 20px;
        padding-left: 24px;
        padding-right: 24px;

        border: 1px solid rgb(218, 221, 225);
        // border-radius: 3px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // margin-bottom: 16px;
        // margin-top: 16px;

        .divider {
            border: none !important;
        }

        @include mediaquery(md, max) {
            // margin: 50px 0px;
            overflow-x: scroll;
            height: 42px;
            padding-bottom: 0px;
            border-bottom: #e6e6e6 solid 1px;
        }

        // @include mediaquery(md, max) {
        //     margin: 50px 30px;
        // }

        .option-wrapper {
            display: flex;
            align-items: center;

            @include mediaquery(md, max) {
                width: 775px;
                // padding-bottom: 15px;
            }

            .option {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #AAAAAA;
                margin-right: 20px;
                cursor: pointer;

                @include mediaquery(md) {
                    font-size: 14px;
                    line-height: 25px;
                    margin-right: 35px;
                }

                @include hover-underline-animation(#000);

                &.selected {
                    color: #000;

                    &::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

                &.not-selected {
                    color: #AAAAAA;
                }

                &::after {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 4px;
                    bottom: -23px;
                }
            }
        }

        .divider {
            opacity: 0.1;
            border: 1px solid #000000;
            transform: rotate(0.26deg);
            margin-top: 23px;
        }
    }

    #overview {
        @include mediaquery(md, max) {
            padding-top: 0 !important;
        }
    }

    .overview-section-wrapper {
        display: flex;
        gap: 50px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 170px;
        min-height: 400px;
        background: #fff;
        padding: 24px 24px 0 24px;
        border: 1px solid rgb(218, 221, 225);
        border-radius: 3px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-bottom: 16px;
        margin-top: -1px;

        @include mediaquery(md, max) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0px;
            padding-right: 0;
            padding-left: 0;
        }

        .product-image-wrapper {
            // height: 400px;
            width: 400px;
            // border: #f1f1f1 solid 1px;
            border-radius: 5px;
            position: relative;

            @include mediaquery(md, max) {
                transform: scale(.8);
                margin-top: -51px;
                padding-top: 30px;
            }

            .image-slider {
                .image-wrap {
                    width: 400px;
                    height: 400px;
                    // border: 1px solid #CCCCCC;
                    border-radius: 5px;

                    @include mediaquery(md, max) {
                        // width: unset;
                        margin-left: 5px;
                    }

                    img {
                        width: 400px;
                        height: 400px;
                        object-fit: contain;
                        border-radius: 5px;
                    }

                    div {
                        div {
                            background-color: white;
                            z-index: 1;
                        }
                    }
                }

                .slider-wrapper {
                    .slides-warp {
                        height: 400px !important;

                        .slides {
                            height: 100vh;
                        }

                        .chevron-wrap {

                            .btn-next {
                                svg {
                                    height: 20px;
                                    width: 20px;
                                    zoom: 60%;
                                }
                            }

                            .btn-prev {
                                svg {
                                    height: 20px;
                                    width: 20px;
                                    zoom: 60%;
                                }
                            }

                            .btn-next,
                            .btn-prev {
                                cursor: pointer;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
                                border-radius: 17.5px;
                                height: 25px;
                                width: 25px;

                                // svg {
                                //     height: 20px;
                                //     width: 20px;
                                //     zoom: 60%;
                                // }

                                &:hover {
                                    background: $secondary-color;

                                    svg {
                                        fill: #fff;
                                    }
                                }
                            }

                            .btn-prev {
                                left: -5%;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                zoom: 120%;

                                >div {
                                    display: flex;
                                }
                            }

                            .btn-next {
                                right: -3%;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                zoom: 120%;

                                >div {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }

            .image-show-slider-wrapper {
                position: relative;

                .image-show-slider {
                    display: flex;
                    margin: 20px 0;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    position: relative;
                    scroll-behavior: smooth;
                    padding-bottom: 10px;
                    position: relative;

                    @include mediaquery(md, max) {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        overflow-x: auto;
                    }

                    &.center {
                        align-items: center;
                        justify-content: center;
                    }

                    .image-wrap {
                        height: 117px;
                        width: 117px;
                        border: 1px solid #CCCCCC;
                        border-radius: 5px;
                        margin-right: 20px;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            height: 117px;
                            width: 117px;
                            object-fit: contain;
                            border-radius: 5px;
                        }

                        &.selected-image {
                            border: 1px solid #3357FF;
                        }
                    }
                }

                .prev-btn,
                .next-btn {
                    position: absolute;
                    left: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    background-color: #3357FF;
                    border: none;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: #3357FF solid 1px;

                    &:focus {
                        box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                        transition: opacity .25s, box-shadow .25s
                    }

                    svg {
                        fill: #fff;
                        height: 20px;
                        width: 20px;
                        zoom: 70%;
                    }

                    &:hover {
                        background-color: #fff;

                        svg {
                            fill: $secondary-color;
                        }
                    }
                }

                .next-btn {
                    left: auto;
                    right: -15px;
                }

                .prev-btn {
                    svg {
                        position: relative;
                        left: 3px;
                    }
                }
            }

            .product-additional-image-gallery {
                position: relative;

                .image-wrap {
                    background-color: #FFFFFF;
                    border-radius: 10px;

                    img {
                        width: 100%;
                        height: 300px;
                        border-radius: 0;
                        object-fit: cover;
                        cursor: pointer;

                        // transition: transform .2s;
                        @include mediaquery(md, max) {
                            width: 300px;
                        }

                        &:hover {
                            // transform: scale(1.2);
                        }
                    }

                    .pro-img {
                        width: 310px;
                        height: 300px;
                    }
                }

                .chevron-wrap {

                    .btn-prev,
                    .btn-next {
                        padding: 0;

                        .next-arrow,
                        .prev-arrow {
                            background: #fff;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 30px;
                            width: 30px;
                            cursor: pointer;

                            svg {
                                zoom: 70%;
                            }

                            &:hover {
                                background: $secondary-color;

                                svg {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }

                .disk-warp {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    bottom: 0%;
                    background: rgba(0, 0, 0, 0.4);
                    backdrop-filter: blur(10px);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 15px;

                    .disk {
                        background: #E7E7EA;
                        opacity: 0.5;

                        &.fill {
                            background: #E7E7EA;
                            opacity: 1;
                            height: 10px;
                            width: 10px;
                        }
                    }
                }
            }

            .no-product-image-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #AAAAAA;
                margin-right: 20px;
                // cursor: pointer;
                position: relative;
                height: 400px;
                width: 400px;
                border: #f1f1f1 solid 1px;
                margin-bottom: 20px;
            }
        }

        .product-info-wrapper {
            @include mediaquery(md, max) {
                padding: 0 15px;
                width: 86%;
            }

            .variant-count {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #262626;
                text-transform: uppercase;

                @include mediaquery(md, max) {
                    margin-top: 40px;
                }
            }

            .variant-dropdown {
                margin-bottom: 20px;

                select {
                    width: 100%;
                    height: 50px;
                    background: #F3F3F4;
                    border-radius: 5px;
                    padding: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #080C29;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    margin-top: 20px;
                    border: #C9C9C9 solid 1px;
                    font-family: $font-inter;

                    &:hover {
                        border: rgba(0, 0, 0, 0.87) solid 1px;
                    }
                }
            }

            .product-name {
                // margin-top: 20px;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #262626;
                // margin-top: 30px;
                text-transform: capitalize;
                word-break: break-word;
                border-bottom: 1px dashed rgb(218, 221, 225);
                padding-bottom: 20px;

                @include mediaquery(md, max) {
                    font-size: 20px;
                }
            }

            .product-brand {
                // margin-top: 12px;
                font-weight: 500;
                font-size: 11px;
                line-height: 17px;
                color: rgba(0, 0, 0, 0.6);
                text-transform: uppercase;
                display: flex;
                align-items: center;
                word-break: break-all;

                @include mediaquery(md, max) {
                    // font-size: 20px;
                }
            }

            .product-price-wrapper {
                ul {
                    padding: 0;
                }

                p {
                    word-break: break-all;
                    margin: 0;
                }
            }

            .product-price-wrapper,
            .product-selling-item-wrapper {
                margin-top: 20px;
                display: flex;
                gap: 50px;
                border-bottom: 1px dashed rgb(218, 221, 225);
                padding-bottom: 20px;
                margin-top: 20px;

                @include mediaquery(md, max) {
                    flex-direction: column;
                    gap: 20px;
                    padding-bottom: 30px;
                    text-align: left;
                }

                .value {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;
                    color: #262626;
                    mix-blend-mode: normal;
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    span {
                        display: flex;
                        word-break: break-all;
                    }

                    .d-flag {
                        zoom: 70%;
                    }

                }

                .arabic {
                    direction: rtl;

                    @include mediaquery(md, max) {
                        direction: ltr;
                    }
                }


                .margin-value {
                    // color: $secondary-color;
                }

                .margin-top-15 {
                    margin-top: 15px;
                    ;
                }

                .label {
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 17px;
                    color: rgb(0 0 0 / 60%);
                    text-transform: uppercase;
                    margin-top: 5px;
                }
            }

            .bor-none {
                border-width: 0;
            }

            .product-selling-item-wrapper {
                // border-bottom: none;
            }
        }


    }

    .exit-preview-wrapper {
        background: $secondary-color;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
        margin: 0 -50px;

        .text-wrap {
            display: flex;

            .heading,
            .description {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
            }

            .description {
                font-weight: 500;
                margin-left: 5px;
            }
        }

        .exit-text {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            text-decoration-line: underline;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    .product-details-wrapper,
    .technical-specifiction-wrapper,
    .certifications-wrapper,
    .distribution-wrapper,
    .marketing-wrapper {
        margin-top: 5px;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        // padding-bottom: 100px;
        display: block;
        gap: 50px;

        .left-div {
            width: auto;
            font-weight: 600;
            font-size: 16px;
            line-height: 29px;
            color: #262626;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            // padding-bottom: 30px;
            // margin-bottom: 15px;
            padding: 15px 24px 15px 24px;
            background: #fff;
        }

        .right-div {
            width: auto;
            // border: 1px solid rgba(0, 0, 0, 0.1);
            border-top: 0;
            background: #fff;
            padding: 0 24px 24px 24px;

            .heading-parent-text {
                font-size: 14px;
                font-weight: 600;
                margin: 20px 0;
            }

            .pt-10 {
                padding-top: 10px;
            }

            .product-two-col {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 15px;

                @include mediaquery(md, max) {
                    flex-direction: row;
                }

                .distri-col {
                    width: 100% !important;

                    @include mediaquery(md, max) {
                        width: unset !important;
                    }
                }

                .retailers-list {
                    display: flex;
                    align-items: center;
                    flex-basis: 45%;
                    justify-content: space-between;

                    .chips-list {
                        margin-top: 0 !important;
                        margin-left: 10px;
                    }
                }

                &.in-a-col {
                    flex-wrap: nowrap;
                    flex-direction: column;
                }

                .inline-col-items {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    @include mediaquery(md, max) {
                        align-items: flex-start;
                    }

                    .heading-text {
                        width: 500px;

                        @include mediaquery(md, max) {
                            width: 100%;
                        }
                    }

                    .pi-htext {
                        line-height: 15px;
                        padding-right: 10px;
                        flex-basis: 343px;
                    }

                    .pi-clist {
                        margin-top: 0;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                        font-size: 14px;
                        line-height: 15px;
                    }
                }

                .inline-col-items-2 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    @include mediaquery(md, max) {
                        align-items: flex-start;
                    }

                    .heading-text {
                        width: 500px;

                        @include mediaquery(md, max) {
                            width: 100%;
                        }
                    }
                }

                .product-vdo-items {
                    flex: 33.3;

                    .description-text {
                        .product-additional-image-gallery {
                            .slider-wrapper {
                                .slides-warp {
                                    .slides {
                                        .absolute {
                                            position: unset;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
                .image-viewer-wrapper {
                    .image-view-popup {
                        zoom: 70%;
                        
                        // img {
                        //     width: 50%;
                        //     height: auto;
                        // }
                    }
                }

                .product-items {
                    width: 50%;
                    padding: 5px 0;

                    @include mediaquery(md, max) {
                        width: 100%;
                        flex-direction: column;
                    }

                    .m-i {
                        width: 5%;

                        @include mediaquery(md, max) {
                            width: 100%;
                        }

                        .nutri-img {
                            width: 100%;
                            height: auto;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                }

                .detail-carosuel {
                    // flex: unset;
                    width: 100%;

                    .heading-text {
                        margin-bottom: 5px;
                    }

                    .image-wrap {
                        height: 300px;
                        // width: 250px;

                        img {
                            height: 300px;
                            // width: 250px;
                            object-fit: contain;
                        }
                    }
                }
            }

            .heading-text {
                font-weight: 500;
                font-size: 11px;
                line-height: 30px;
                color: rgba(0, 0, 0, .6);
                text-transform: uppercase;
            }

            .description-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                color: #262626;
                // margin-left: 20px;
                column-gap: 20px;
                row-gap: 10px;
                width: calc(100%);

                @include mediaquery(md, max) {
                    width: 100%;
                    row-gap: 10px;
                }

                .product-video-iframe {
                    height: 300px;
                    width: auto;
                    border-radius: 10px;
                }

                .retailers-list {
                    .chips-list {
                        margin-top: 10px;
                    }
                }

                p {
                    margin: 0;
                }
            }



            .nutritional-label,
            .mutliselect-label {
                // margin: 20px 0 20px 0;

                img {
                    height: 300px;
                }
            }

            .nutritional-label {
                flex-basis: 250px !important;

                @include mediaquery(md, max) {
                    margin-top: 15px;
                    flex-basis: unset !important;
                }
            }
        }

        .product-additional-image-gallery {
            position: relative;
            width: 300px;

            .slider-wrapper {
                .slides-warp {
                    .slides {
                        border: #f1f1f1 solid 1px;
                        padding: 10px;

                        .prevSibling {
                            transform: translateX(-110%);
                        }
                    }
                }
            }

            .image-wrap {
                background-color: #FFFFFF;
                border-radius: 10px;

                img {
                    width: 100%;
                    height: 300px;
                    border-radius: 0;
                    object-fit: cover;
                    cursor: pointer;

                    // transition: transform .2s;
                    @include mediaquery(md, max) {
                        width: 300px;
                    }

                    &:hover {
                        // transform: scale(1.2);
                    }
                }

                .pro-img {
                    width: 100%;
                    height: auto;
                }
            }

            .chevron-wrap {

                .btn-prev,
                .btn-next {
                    padding: 0;

                    .next-arrow,
                    .prev-arrow {
                        background: #fff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 30px;
                        width: 30px;
                        cursor: pointer;

                        svg {
                            zoom: 70%;
                        }

                        &:hover {
                            background: $secondary-color;

                            svg {
                                fill: #fff;
                            }
                        }
                    }
                }
            }

            .disk-warp {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                bottom: 0%;
                background: rgba(0, 0, 0, 0.4);
                backdrop-filter: blur(10px);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 15px;

                .disk {
                    background: #E7E7EA;
                    opacity: 0.5;

                    &.fill {
                        background: #E7E7EA;
                        opacity: 1;
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }

    .certifications-wrapper {
        border: 1px solid rgb(218, 221, 225);
        border-radius: 3px;
        margin-bottom: 16px;
    }

    .marketing-wrapper {
        border: 1px solid rgb(218, 221, 225);
        border-radius: 3px;
        // padding-bottom: 16px;
    }

    .certifications-wrapper {
        .right-div {
            // background: #F3F3F4;
            // border-radius: 5px;
            padding-top: 5px;

            .description-text {
                background: #F3F3F4;
                border-radius: 5px;

                div {
                    &:last-of-type {
                        .certification-wrap {
                            border-bottom: none;
                        }
                    }
                }
            }

            .certification-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // cursor: pointer;
                height: 19px;
                border-bottom: rgb(0 0 0 / 10%) solid 1px;
                padding: 15px 5px 15px 20px;

                @include mediaquery(md, max) {
                    height: auto;
                    padding: 10px 0px 10px 15px;
                }

                .text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #3A3A3A;

                    @include mediaquery(md, max) {
                        width: calc(100% - 150px);
                    }
                }

                .button-wrap {

                    // display: none;
                    @include mediaquery(md, max) {
                        display: block;
                    }

                    button {
                        margin-right: 10px;
                        height: 30px;
                    }

                    .view-btn,
                    .download-btn {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        text-align: center;
                        color: #97A8B8;
                        border: 1px solid #97A8B8;
                        border-radius: 5px;
                    }

                    .download-btn {
                        color: #FFFFFF;
                        background: #4361EE;
                        border-radius: 5px;
                    }
                }

                &:hover {
                    .text {
                        color: #4361EE;
                    }

                    .button-wrap {
                        display: block;
                    }
                }

                .noHover {
                    pointer-events: none;
                }

            }

            .divider {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                margin: 15px -20px;
            }
        }
    }
}

.website-product-details-wrapper .product-details-wrapper .right-div .product-two-col .product-items {
    flex-basis: 50%;
    width: auto !important;
}

.display-block {
    display: block !important;
}

.chips-list {
    // margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    .chips {
        background: #e3e3e3 !important;
        border-radius: 17.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        color: gray;
        // opacity: 0.65;
        margin-right: 10px;
        padding: 5px 7px 5px 11px;
        border: gray dashed 1px;
        gap: 5px;
    }
}

.image-viewer-wrapper {
    .image-view-popup {
        width: auto;
        @include mediaquery(md, max) {
            width:75%;
        }
        img {
            &.nutri-la {
                border: #f1f1f1 solid 1px;
            }
        }

        .modal-title {
            margin-bottom: 10px;
        }

        .scroll-con {
            overflow-y: scroll;
            height: 400px;
            padding-right: 20px;

            div {
                p {
                    &:first-of-type {
                        margin-top: 0;
                    }
                }
            }
        }

        &.img-padding {
            padding: 5px 10px 10px 10px !important;
        }

        .svg-close-icon {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;
            margin-top: -20px;
            margin-left: 46px;

            span {
                cursor: pointer;

                &:hover {
                    svg {
                        path {
                            fill: red;
                        }
                    }
                }
            }
        }

        .svg-close-icon-2 {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 100%;

            span {
                cursor: pointer;

                &:hover {
                    svg {
                        path {
                            fill: red;
                        }
                    }
                }
            }
        }
    }
}