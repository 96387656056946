.my-brand-wrapper {

    textarea::-webkit-scrollbar,
    #menu-yearofestablish .MuiPaper-elevation::-webkit-scrollbar {
        width: 5px;
        height: 4px
    }


    textarea::-webkit-scrollbar,
    #menu-yearofestablish .MuiPaper-elevation::-webkit-scrollbar {
        width: 5px;
        height: 4px
    }

    textarea::-webkit-scrollbar-track,
    #menu-yearofestablish .MuiPaper-elevation::-webkit-scrollbar-track {
        background: #f1f1f1
    }

    textarea::-webkit-scrollbar-thumb,
    #menu-yearofestablish .MuiPaper-elevation::-webkit-scrollbar-thumb {
        background: $secondary-color;
        border-radius: 15px
    }

    .divider {
        margin-top: 20px;
        opacity: 0.1;
        border: 1px solid #000000;
    }

    .brands-wrapper {
        margin: 30px 50px;
        display: flex;

        @include mediaquery(md, max) {
            margin: 30px 0;
        }

        .brand-scroll-wrap {
            max-width: 90%;
            position: relative;

            @include mediaquery(md, max) {
                max-width: 70%;
            }

            .added-brand-wrapper {
                display: flex;
                overflow-x: scroll;
                overflow-y: hidden;
                // position: relative;

                @include mediaquery(md) {
                    overflow-x: auto;
                    overflow-y: hidden;
                    // position: relative;
                }

                .brand-list-slider-container {
                    display: flex;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                    padding: 3px;

                    @include mediaquery(md, max) {
                        overflow-x: auto;
                    }
                }

                .image-wrap {
                    width: 100px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    margin-right: 20px;
                    cursor: pointer;
                    overflow: hidden;
                    position: relative;
                    flex-shrink: 0;
                    padding: 5px;

                    /* Add the blur effect */
                    // filter: blur(2px);
                    // -webkit-filter: blur(2px);
                    &:before {
                        // content: '';
                        // background: #fff;
                        // width: 100%;
                        // height: 100%;
                        // opacity: 0.6;
                    }

                    &.selected-brand {
                        border: 1px solid $secondary-color;
                        border-radius: 10px;
                        /* Add the blur effect */
                        // filter: blur(0px);
                        // -webkit-filter: blur(0px);
                        box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                        transition: opacity .25s, box-shadow .25s
                    }

                    &:hover {
                        box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                        transition: opacity .25s, box-shadow .25s;
                        border: 1px solid $secondary-color;
                    }

                    .img {
                        height: 90px;
                        width: 100%;
                        object-fit: contain;
                        zoom: 70%;
                        -moz-transform: scale(.7);
                    }
                }

                .prev-btn,
                .next-btn {
                    position: absolute;
                    left: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    background-color: $secondary-color;
                    border: none;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: $secondary-color solid 1px;

                    &:focus {
                        box-shadow: 0px 0px 0 3px rgb(216 223 255) !important;
                        transition: opacity .25s, box-shadow .25s
                    }

                    svg {
                        fill: #fff;
                        height: 20px;
                        width: 20px;
                        zoom: 70%;
                    }

                    &:hover {
                        background-color: #fff;

                        svg {
                            fill: $secondary-color;
                        }
                    }
                }

                .next-btn {
                    left: auto;
                    right: -15px;
                }

                .prev-btn {
                    svg {
                        position: relative;
                        left: 3px;
                    }
                }
            }

            // &.w-95 {
            //     display: flex;
            //     width: 95%;
            //     @include mediaquery(md, max) {
            //         width: 72%;
            //     }
            // }
        }

        .add-brand-wrap {
            display: flex;
            align-items: center;

            .line {
                border-left: 1px solid #C7D5E1;
                height: 70px;
                margin: 0 20px 0 0;
            }

            .add-brand-wrapper {
                border: 2px dashed rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                width: 280px;
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #10243D;

                &:hover {
                    border: 2px dashed $secondary-color;
                    color: $secondary-color;
                }

                @include mediaquery(md, max) {
                    max-width: 80px;
                }

                @include mediaquery(md) {
                    width: 100px;
                }

                svg {
                    fill: rgba(67, 97, 238, 0.8);
                }
            }
        }
    }

    .company-cover-image {
        padding: 60px 0 20px 0;
    }

    .upload-cover-wrapper {
        background: #E7E7E7;
        height: auto;
        margin: 20px -16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1.5px dashed #979797;

        .img-info {
            @include mediaquery(md, max) {
                padding: 0 50px;
                text-align: center;
            }
        }

        @include mediaquery(md, max) {
            height: auto;
        }

        &:hover {
            border: 1.5px dashed $secondary-color;

            .camera-icon-wrapper {
                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }

            .text {
                color: $secondary-color;
            }
        }

        @include mediaquery(md) {
            margin: 20px -24px;
        }

        .camera-icon-wrapper {
            width: 146px;
            height: 146px;
            background: #F1F1F1;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // margin-top: 43px;
        }

        .text {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #262626;
            margin-top: 20px;

            @include mediaquery(md, max) {
                font-size: 16px;
            }
        }

        .cover-image-wrapper {
            width: 100%;
            background-position: center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            height: auto;

            #edit-cover-image {
                position: absolute;
            }

            @include mediaquery(md, max) {
                height: auto;
            }

            @include mediaquery(md) {
                &:hover {
                    .edit-cover-image {
                        span {
                            visibility: visible;
                        }
                    }
                }
            }

            img {
                height: auto;
                width: 100%;
                object-fit: cover;
            }

            .edit-cover-image {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: 10px;
                cursor: pointer;

                position: absolute;
                right: 0;
                top: 0;

                @include mediaquery(md) {
                    visibility: hidden;
                }

                .edit-img-icon {
                    &:hover {
                        svg {
                            circle {
                                fill: #333;
                            }
                        }
                    }
                }

                .delete-icon {
                    background-color: rgb(51, 87, 255);
                    border-radius: 50%;
                    border: 2px solid #fff;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 15px;

                    svg {
                        fill: #fff;
                    }
                }


                .delete-icon {
                    &:hover {
                        background-color: #333;

                        svg {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    .brand-detail-wrapper {
        display: block;
        margin-left: 0px;
        margin-right: 0px;

        @include mediaquery(md) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            // margin-top: -90px;
            margin-left: 50px;
            margin-right: 50px;
        }

        .brand-info-wrapper {
            display: flex;
            align-items: flex-end;
            width: 100%;

            @include mediaquery(md, max) {
                flex-direction: column;
                align-items: center;
                gap: 30px;
            }

            .brand-img {
                width: 160px;
                height: 160px;
                background: #fff;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                border: #F1F1F1 solid 1px;
                padding: 10px;
                margin-top: -90px;
                @include mediaquery(md, max) {
                    margin-top: unset;
                }

                .camera-icon {
                    position: absolute;
                    right: -15%;
                    bottom: -10%;
                    width: 56px;
                    height: 56px;
                    background: #F1F1F1;
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        height: 30px;
                        width: 30px;
                    }
                }

                img {
                    width: 160px;
                    height: 160px;
                    object-fit: contain;
                    // zoom: 113%;
                }
            }

            .brand-information {
                margin-left: 50px;
                width: calc(100% - 160px);

                @include mediaquery(md, max) {
                    margin-left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .brand-name {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 29px;
                    display: flex;
                    align-items: center;
                    color: #262626;
                    margin-bottom: 0;

                    @include mediaquery(md, max) {
                        font-size: 16px;
                    }
                }

                .brand-tagline {
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #262626;
                    opacity: 0.7;
                    cursor: pointer;

                    @include mediaquery(md, max) {
                        font-size: 14px;
                    }
                }

                .close-icon {
                    cursor: pointer;

                    &:hover {
                        path {
                            // stroke: red;
                            fill: red;
                        }
                    }
                }

                .tagline-input {
                    border: none;
                    outline: none;
                    border-bottom: 1px solid gray;
                    font-size: 16px;
                    font-family: $font-inter;
                }
            }
        }

        .brand-more-option-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 30px 0;

            @include mediaquery(md) {
                display: flex;
                align-items: center;
                margin: 0;
                top: -7px;
                position: relative;
            }

            .brand-publish-catalogue {
                .button {
                    background: rgba(255, 255, 255, 0.7);
                    border: 1px solid $secondary-color;
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: $secondary-color;
                }
            }

            .line {
                border-left: 1px solid #C7D5E1;
                height: 25px;
                margin: 0 20px;
            }

            .catalogue-preview,
            .more-option-icon-wrapper {
                margin: 0 10px;
                position: relative;

                .more-option-icon {
                    cursor: pointer;
                }

                svg {
                    height: 25px;
                    width: 25px;

                    @include mediaquery(md) {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
    }

    .brand-manage-section {
        margin: 50px 50px 30px 50px;

        @include mediaquery(md, max) {
            margin: 50px 0px;
            border-bottom: #e6e6e6 solid 1px;
            padding-bottom: 21px;
        }

        .option-wrapper {
            display: flex;
            align-items: center;

            .option {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #AAAAAA;
                margin-right: 20px;
                cursor: pointer;

                @include mediaquery(md) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-right: 35px;
                }

                @include hover-underline-animation(#000);

                &.selected {
                    &::after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

                &::after {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    height: 4px;
                    bottom: -21px;
                }
            }
        }
    }

    .product-filter-section {
        margin: 30px 50px;
        margin-bottom: 180px;

        @include mediaquery(md, max) {
            margin-bottom: 30px;
            margin: 30px 0;
        }

        .product-add-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .product-count {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                color: #262626;

                @include mediaquery(md) {
                    // font-size: 16px;
                    line-height: 29px;
                }
            }

            .product-add-btn {
                .upload-btn {
                    border: 1px solid #D4D4D4;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #262626;
                    height: 45px;
                    text-transform: capitalize;
                }

                .add-upload-btn {
                    background: $secondary-color;
                    border-radius: 5px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                    height: 45px;
                    text-transform: capitalize;
                }
            }
        }

        .filter-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;

            @include mediaquery(md, max) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                gap: 15px;
            }

            .search-bar {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                @include mediaquery(md, max) {
                    width: 100%;
                }

                .search-input {
                    position: relative;
                    border-radius: 5px;
                    width: 200px;
                    margin-top: 0;
                    margin-bottom: 0;

                    @include mediaquery(md) {
                        width: 400px;
                    }

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    ::placeholder {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 24px;
                        color: #262626;
                        mix-blend-mode: normal;
                        opacity: 0.7;
                    }

                    >div {
                        padding-left: 10px;
                    }

                    input {
                        padding: 6px 25px 6px 5px !important;
                        width: 100%;
                        font-family: $font-inter;
                    }
                }

                .search-icon-wrap {
                    background: #000000;
                    border-radius: 5px;
                    height: 36px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0 0 10px;
                    cursor: pointer;
                    border: #000 solid 1px;

                    svg {
                        path {
                            stroke: #fff;
                        }
                    }

                    &:hover {
                        background: #fff;
                        border: #000 solid 1px;

                        svg {
                            path {
                                stroke: #000;
                            }
                        }
                    }

                    &:focus {
                        box-shadow: 0px 0px 0 3px rgb(211, 210, 210) !important;
                        transition: opacity .25s, box-shadow .25s
                    }
                }

                .search-icon {
                    position: absolute;
                    top: 58%;
                    transform: translate(-50%, -50%);
                    left: 20px;
                    cursor: pointer;
                }

                .close-icon {
                    position: absolute;
                    top: 54%;
                    transform: translate(-50%, -50%);
                    right: 60px;
                    cursor: pointer;
                    zoom: 80%;
                }
            }

            .filters {
                display: flex;

                .menu {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #262626;
                    opacity: 1;
                    margin-right: 10px;

                    svg {
                        fill: #686868;
                        transform: rotate(90deg);
                        margin-left: 5px;
                    }
                }

                .more-filter-option-icon-wrapper {
                    margin: 0 10px;
                    position: relative;
                    cursor: pointer;

                    .more-option-icon {
                        cursor: pointer;
                    }

                    .tooltip-element-filter-tooltip,
                    .tooltip-element-action-tooltip {

                        .tooltip-icon-filter-tooltip,
                        .tooltip-icon-action-tooltip {
                            display: flex !important;
                            justify-content: center !important;
                            align-items: center !important;
                        }
                    }

                    &:hover {
                        color: $secondary-color;

                        div {
                            div {
                                svg {
                                    path {
                                        fill: $secondary-color;
                                    }
                                }
                            }
                        }
                    }

                    svg {
                        height: 25px;
                        width: 25px;

                        @include mediaquery(md) {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }

    .add-brand-popup-wrapper {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 6;

        .add-brand-popup {
            align-items: center;
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 50%;
            overflow: auto;
            padding: 30px;
            position: fixed;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 73%;
            z-index: 1;

            @include mediaquery(md) {
                width: 513px;
            }
        }

        .first-heading {
            font-weight: 600;
            font-size: 28px;
            line-height: 29px;
            color: #262626;

            @include mediaquery(md, max) {
                font-size: 20px;
            }
        }

        .second-heading {
            margin: 5px 0 20px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #696969;
        }

        form {
            position: relative;
            width: 100%;

            div {
                width: 85%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;

                @include mediaquery(md, max) {
                    width: 100%;
                }

            }

        }

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                cursor: pointer;
                height: 25px;
                width: 25px;
                fill: #C4C4C4;
            }

            &:hover {
                svg {
                    fill: red;
                }
            }
        }

        .btn-primary-after-login {
            width: 73%;

            @include mediaquery(md, max) {
                width: 100%;
            }
        }

        .add-new-product-button {
            width: 73%;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            text-transform: capitalize;

            @include mediaquery(md, max) {
                width: 100%;
            }
        }

        .field-err-msg {
            margin-bottom: 10px;
        }
    }

    button {
        text-transform: capitalize;
    }


    .profile-section-wrapper {
        margin: 0 50px;

        @include mediaquery(md, max) {
            margin: 0;
        }

        .first-heading {
            font-weight: 600;
            font-size: 16px;
            line-height: 29px;
            color: #262626;
            font-family: $font-inter;
        }

        .form-input-control {

            .brand-origin-section {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include mediaquery(md, max) {
                    flex-direction: column;
                }

                .country-brand,
                .year-establish {
                    width: 50%;
                    font-size: 14px;

                    @include mediaquery(md, max) {
                        width: 100%;
                        margin: 0;
                    }

                    .form-profile-field {
                        #country {
                            div {
                                font-size: 14px;
                                display: flex;
                                gap: 10px;
                                height: 16px;
                                justify-content: flex-start;
                                align-items: center;
                                font-family: $font-inter;
                            }
                        }
                    }

                    fieldset {
                        legend {
                            display: none !important;
                        }
                    }
                }

                .story-mission-vision {
                    width: 100%;
                }

                .social-links {

                    // width: 33.5%;
                    .label {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }

                    &.linkedIn, &.website-l {
                        svg {
                            width: 20px;
                            height: auto;
                        }
                    }

                    .form-profile-field {
                        width: 100%;

                        @include mediaquery(md, max) {
                            width: 100%;
                        }
                    }

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    .field-err-msg {
                        @include mediaquery(md, max) {
                            position: relative;
                            margin-top: 5px;
                        }
                    }
                }

                .form-profile-field {
                    border-radius: 5px;
                    background: #F3F3F4;

                    @include mediaquery(md, max) {
                        width: 100%;
                    }

                    fieldset {
                        // border-right-width: 1px;
                        // border-top-right-radius: 5px;
                        // border-bottom-right-radius: 5px;
                    }
                }


                .country-brand {
                    margin-right: 50px;

                    @include mediaquery(md, max) {
                        margin-right: 0;
                    }
                }

                .input {
                    width: 100%;
                    margin: 0;
                    height: 50px;
                    border-radius: 5px;

                    input {
                        height: 25px;
                    }
                }
            }

            .social-media-section {
                /* display: flex; */
                /* align-items: center; */
                /* justify-content: unset; */
                gap: 20px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);

                @include mediaquery(md, max) {
                    gap: 0;
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            .label {
                // font-weight: 500;
                // font-size: 16px;
                // line-height: 24px;
                // display: flex;
                // align-items: center;
                // color: #000000;
                margin-bottom: 8px;
            }

            .button-section {

                display: flex;
                align-items: center;
                justify-content: space-between;

                .text {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    color: #313E42;

                    @include mediaquery(md, max) {
                        font-size: 12px;
                    }
                }

                .button-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin: 20px 0;

                    .cancel-btn {
                        border: 1px solid #97A8B8;
                        border-radius: 5px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                        color: #97A8B8;
                        padding: 12px 40px;
                        margin-right: 30px;
                        text-transform: capitalize;
                    }

                    .save-btn {
                        background: $secondary-color;
                        border-radius: 5px;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #FFFFFF;
                        padding: 12px 40px;
                    }
                }
            }
        }
    }

    .copy-product-popup-wrapper {
        background: rgba(0, 0, 0, .6);
        bottom: 0;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 6;
    
        .popup-wrap {
            align-items: center;
            background: #fff;
            border-radius: 12px;
            box-shadow: 0 4px 40px rgb(0 0 0 / 10%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 50%;
            overflow: auto;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 30px;
            position: fixed;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 530px;
            z-index: 1;
            position: relative;
    
            @include mediaquery(md, max) {
                width: 75%;
            }
    
            .close-icon {
                position: absolute;
                top: 5%;
                right: 5%;
                cursor: pointer;
            }

            .heading {
                margin-top: 15px;
                font-size: 20px;
                font-weight: 700;
                line-height: 28px;
                width: 100%;
    
                @include mediaquery(md, max) {
                    font-size: 16px;
                }
            }

            .brand-list {
                width: 100%;
                p {
                    word-break: break-word;
                }
            }

            .button-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
    
                .cancel-btn {
                    // border: 1px solid #7c808e;
                    // height: 36px;
                    // // width: 92px;
                    // background: #fff;
                    // border-radius: 8px;
                    // margin-right: 12px;
                    // font-size: 16px;
                    // color: #7c808e;
                    // font-weight: 600;
                    cursor: pointer;
                    font-family: $font-inter;
                    border: 1px solid #97A8B8;
                    border-radius: 5px;
                    background: #fff;
                    text-align: center;
                    color: #97A8B8;
                    margin-right: 10px;
                    box-shadow: none;
                    font-size: 14px;
                    &:hover {
                        background-color: #97A8B8;
                        box-shadow: none;
                        color: #fff;
                    }
    
                    @include mediaquery(md) {
                    margin-right: 20px;
                    }
                }
    
                .submit-btn {
                    // height: 36px;
                    // width: 92px;
                    background: #249aff;
                    border-radius: 8px;
                    border: none;
                    font-size: 16px;
                    color: #fff;
                    font-weight: 600;
                    cursor: pointer;
    
                    background-color: $primary-btn-color;
                    text-transform: capitalize;
                    font-family: $font-inter;
                    color: #fff;
                    border-radius: 5px;
                    
                    &:hover {
                        background: lighten($primary-btn-color, 10%);
                    }   
                    &:focus {
                        box-shadow:0px 0px 0 3px rgb(216 223 255) !important;
                        transition:opacity .25s,box-shadow .25s
                    }
                }
            }

            .MuiFormControl-root {
                label {
                    background: #fff;
                    width: 76px;
                }
            }
        }
    
    }
}


  .read-or-hide{
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    // margin-top: 5px;
  }